<div class="row wrapper">
  <div class="col-3 title">
    <span style="color: red; font-size: 20px">*</span> Full name
  </div>
  <div class="col-9 work-field">
    <div class="md-form" (document:focusout)="changeEdit()" (document:focusin)="changeEdit()">
      <input [(ngModel)]="fullName" mdbActive type="text" id="fullName1" required #inputFullName="ngModel">
      @if (inputFullName.invalid && inputFullName.touched) {
      <span style="color: red">Enter full name</span>
      }
    </div>
  </div>
</div>