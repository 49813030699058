<div class="chat-container">
    @if (isSpinner) {
    <div class="spinner">
        <mat-spinner [diameter]="100"></mat-spinner>
    </div>
    }
    @if (isShowTextIfNoConversations) {
    <div class="no-conversations-wrapper">
        <div class="no-conversations">{{textIfNoConversations}}</div>
    </div>
    }

    <pd-chat-selector #chatSelector (onChangeSearch)="onChangeSearch()" (onLazyLoading)="onLazyLoading()"
        [isGlobalChat]="isGlobalChat"></pd-chat-selector>

    <div class="chat-box">
        <div #targetHeader class="header-container">
            <pd-chat-header></pd-chat-header>
        </div>
        <div #targetMessagesList class="messages-container">
            <pd-chat-view></pd-chat-view>
        </div>
        <div #targetMessagesInput>
            <pd-chat-footer [isGlobalChat]="isGlobalChat"
                [isCandidateConversation]="isCandidateConversation" (onGrowCheck)="autoGrow()"></pd-chat-footer>
        </div>
    </div>
</div>