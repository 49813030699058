<div class="pd-custom-select-without-lazy">
  <div class="custom">
    <div class="custom-header" (click)="openSelect();$event.stopPropagation()">
      <span class="custom-input" bootstrapTooltip="{{getTooltip()}}" data-html="true"
        placement="bottom">{{(isFixedStartLabel) ? startLabel :
        label}}</span>
      <span>
        <i class="material-icons arrow" style="color:rgba(0,0,0,.54);">
          arrow_drop_down
        </i>
      </span>
    </div>
    <div class="custom-body" [ngClass]="{'visible-select': isOpenSelect, 'hidden-select': !isOpenSelect }"
      (clickOutside)="closeCustomSelect()">
      @if (displaySearch) {
      <div class="custom-search">
        <input (keyup)="onSearch()" type="text" placeholder="Search" (click)="$event.stopPropagation()"
          [(ngModel)]="searchText" [formControl]="multiNumberFilter">
      </div>
      }
      <div class="group-options" #selectContainer>
        <div class="list-options" [ngStyle]="{'max-height.px': !isMultiple ? 280 : 'auto'}">
          <div [hidden]="!isShowSelectAll || !isMultiple || !isNeedShowSelectAll" class="select-all">
            <div [hidden]="!isMultiple">
              <button #selectAll class="button" [disabled]="selectAllDisabled"
                (click)="selectAllData();$event.stopPropagation()">Select All</button>
              <button #deselectAll class="button" style="margin-left: 5px;"
                (click)="deselectAllData();$event.stopPropagation()">Deselect All</button>
            </div>
          </div>
          @for (item of filteredData; track item.id;) {
          <div class="custom-option">
            <span class="option">
              <mat-checkbox [disabled]="item.disabled" [checked]="checkItem(item.id)"
                (change)="selectItem(item, $event)">{{item.name}}
              </mat-checkbox>
            </span>
          </div>
          }
        </div>
      </div>
    </div>
  </div>
</div>