import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Phone } from 'src/app/core/models/Deal';
import { OutboundCallRequest } from 'src/app/core/models/OutboundCallRequest';
import { CallsService } from 'src/app/core/services/calls.service';
import { NotificationService } from 'src/app/core/services/notification.service';

@Component({
  selector: 'pd-call-modal',
  templateUrl: './call-modal.component.html',
  styleUrls: ['./call-modal.component.scss']
})
export class CallModalComponent implements OnInit {
  dealId: number;
  phones: [] = [];
  isCalling = false;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: { dealId: number, phones: [] },
    private dialogRef: MatDialogRef<CallModalComponent>,
    private callsService: CallsService,
    private notificationService: NotificationService
  ) {
    this.dealId = data.dealId;
    this.phones = data.phones;
    console.log(data);
  }

  ngOnInit(): void {
  }

  public getPhoneNumber(phone): string {
    return phone.value ?? phone.tempNewValue;
  }

  async call(phone) {
    this.isCalling = true;
    const phoneNumber = this.getPhoneNumber(phone); 
    const request: OutboundCallRequest = {
      phoneNumber: phoneNumber,
      dealId: this.dealId
    };

    const response = await this.callsService.initiateCall(request);
    if(response.success) {
      this.notificationService.showSuccess(response.message);
    }
    else {
      this.notificationService.showError(response.message);
    }

    this.isCalling = false;
  }

  close(): void {
    this.dialogRef.close();
  }
}
