import { ObjectUtil } from '../utils/object.util';
import { Role } from './Role';

export interface AiAgentConfigModel {
  stageId: number | null;
  agentId: string;
  agentName: string;
  phone: string;
}

export class RolePropertyModel {
  public isTasksTab = false;
  public isUsersTab = false;
  public isAllowedExportLeadDate = false;
  public isAllowedHistoryOfLeads = false;
  public isRepoAccount = false;
  public isWhatsappChat = false;
  public isGlobalChat = false;
  public isAvatarEditor = false;
  public isInSmReport = false;
  public isNotInClothierReport = false;
  public isSeeOthersCms = false;
  public isMarketLeader = false;
  public isAiAgent = false;
  public leaderOf: number[] = [];
  public aiAgentConfigs: AiAgentConfigModel[] = [];
  public role: Role;

  constructor(data?: any) {
    ObjectUtil.extend(data, this);
  }
}
