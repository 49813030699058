@if (spinner) {
<div class="spinner">
  <mat-spinner [diameter]="100"></mat-spinner>
</div>
}

@if (!isBbAccount) {
<div class="row content-wrapper">
  @if (!isResetForm) {
  <div class="col-6">
    <div class="sub-title">
      <h6>New Deal</h6>
    </div>
    <div>
      <div>
        <div class="field-name">
          <pd-field-name (nameChange)="getName($event)"></pd-field-name>
        </div>
        <div class="field-phones">
          <div>
            <div class="row phone-row">
              <div class="col-4 phone-label">
                Phone
              </div>
              <div class="col-8">
                <pd-field-intl-tel #fieldIntlTel [isNeedSaveCancelBtn]="false">
                </pd-field-intl-tel>
              </div>
            </div>
          </div>
        </div>
        <div class="field-emails">
          <div>
            <div class="row email-row">
              <div class="col-4 email-label">
                Email
              </div>
              <div class="col-8">
                <pd-field-emails #fieldEmails [isNeedSaveCancelBtn]="false"></pd-field-emails>
              </div>
            </div>
          </div>
        </div>
        <div class="field-gender">
          <pd-field-gender (genderChange)="getGender($event)"></pd-field-gender>
        </div>
        <div class="field-linkedin-url">
          <pd-field-linkedin-url (linkedinUrlChange)="getLinkedinUrl($event)" [isUrlExists]="false">
          </pd-field-linkedin-url>
        </div>
        <div class="field-ref">
          <pd-field-str (onValueChanged)="setPersonWhoReferred($event)" [fieldValue]="whoReferredText"
            [stageId]="stageId"
            [fieldInvalid]="(!whoReferredText || whoReferredText.length == 0) && (stageId === ALL_STAGES.Referral)"
            field-title="Person Who Referred" [field-required]="stageId === ALL_STAGES.Referral"
            field-placeholder="Please fill in the full name of person who referred"></pd-field-str>
        </div>
        <div class="field-linkedin-location">
          <pd-field-linkedin-location (linkedinLocationChange)="getLinkedinLocation($event)" #locationComponent>
          </pd-field-linkedin-location>
        </div>
        <div class="field-position">
          <pd-field-position (positionChange)="getPosition($event)"></pd-field-position>
        </div>
        <div class="field-company">
          <pd-field-company (companyChange)="getCompany($event)"></pd-field-company>
        </div>
        <div class="field-stages-id">
          <pd-field-stage-id (stagesChange)="getStageId($event)"></pd-field-stage-id>
        </div>
        @if (isAdmin) {
        <div class="field-user-id">
          <pd-field-user-id (userIdChange)="getUserId($event)"></pd-field-user-id>
        </div>
        }
        <div class="field-city">
          <pd-field-city (cityChange)="getCity($event)"></pd-field-city>
        </div>

        <div class="field-address">
          <div class="row wrapper">
            <div class="col title">
              Company Address
            </div>
            <div class="col-8 work-field">
              @if (!address && !isCompanyAddressEdit) {
              <pd-add-value (addValue)="addCompanyAddressValue()">
              </pd-add-value>
              }
              <div style="width: 100%;">
                @if (isCompanyAddressEdit || address) {
                <div class="md-form" (document:focusout)="changeCompanyAddressEdit()">
                  <input [(ngModel)]="address" mdbActive type="text">
                </div>
                }
                <div class="alert-row">
                  @if (!checkAddressIsValid(address)) {
                  <div class="alert alert-danger">
                    {{addressValidationTooltip}}
                  </div>
                  }
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="field-address">
          <div class="row wrapper">
            <div class="col title">
              Home Address
            </div>
            <div class="col-8 work-field">
              @if (!homeAddress && !isHomeAddressEdit) {
              <pd-add-value (addValue)="addHomeAddressValue()">
              </pd-add-value>
              }
              <div style="width: 100%;">
                @if (isHomeAddressEdit || homeAddress) {
                <div class="md-form" (document:focusout)="changeHomeAddressEdit()">
                  <input [(ngModel)]="homeAddress" mdbActive type="text">
                </div>
                }
                <div class="alert-row">
                  @if (!checkAddressIsValid(homeAddress)) {
                  <div class="alert alert-danger">
                    {{addressValidationTooltip}}
                  </div>
                  }
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="required-row">
        <p>{{exception}} @if (duplicateDealId) {
          <a (click)="openDealInNewTab(duplicateDealId);$event.stopPropagation()">View
            deal</a>
          }
        </p>
      </div>
      <div class="cancel-save-deal">
        <button type="button" class="btn btn-danger" (click)="cancelDeal()">Cancel</button>
        <button type="button" class="btn btn-primary" (click)="resetData()">Reset form</button>
        <button type="button" class="btn btn-success btn-submit" (click)="saveDeal()" [disabled]="
        (!name 
        || (name && name.length === 0) || name.trim().length === 0) 
        || !checkContacts()
        || (!linkedinUrl || (linkedinUrl && linkedinUrl.length === 0) || linkedinUrl.indexOf(' ') !== -1)
        || !stageId
        || ((stageId == ALL_STAGES.Referral) && (!whoReferredText || whoReferredText.length == 0)) || !isValidLinkedInLocation() || !isValidCompany() 
        || !checkAddressIsValid(address)
        || !checkAddressIsValid(homeAddress)
        || spinner">
          Save Deal
        </button>
      </div>
    </div>
  </div>
  }
</div>
}

<div class="sticky-wrapper">
  @if (isShowSuccessNewDealAlert) {
  <div class="new-deal-alert-wrapper">
    <div class="new-deal-alert">
      You’ve created <strong>{{this.newDealName}}</strong> deal
      <div class="new-deal-link-wrapper">
        <a class="new-deal-link" (click)="openDealInNewTab(newDealId);$event.stopPropagation()">View deal</a>
        <a class="new-deal-link" [text-copy]="getDealUrl(newDealId)" (click)="tooltip.show();$event.stopPropagation()"
          #tooltip="matTooltip" matTooltip="Copied!" (mouseenter)="$event.stopImmediatePropagation()"
          matTooltipHideDelay="500" placement="top">Copy
          link</a>
      </div>

      <button class="new-deal-link-close-btn close" (click)="isShowSuccessNewDealAlert = false" type="button">
        <span>&times;</span>
      </button>
    </div>
  </div>
  }
</div>