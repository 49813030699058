import { Component, OnInit } from '@angular/core';
import { STAGES_FOR_ALL_PIPELINES } from 'src/assets/stages_new_prospespects';
import { CallEntity } from 'src/app/core/models/CallEntity';
import { CallsService } from 'src/app/core/services/calls.service';

@Component({
  selector: 'pd-calls-list',
  templateUrl: './calls-list.component.html',
  styleUrls: ['./calls-list.component.scss']
})
export class CallsListComponent implements OnInit {
  calls: CallEntity[] = [];
  selectedCall: CallEntity | null = null;

  constructor(private callsService: CallsService) {}

  async ngOnInit() {
    await this.loadCalls();
  }

  private async loadCalls() {
    this.calls = await this.callsService.getAllCalls();
  }

  onSelectCall(call: CallEntity) {
    this.selectedCall = call;
  }

  getStageName(stageId: number): string {
    const stage = STAGES_FOR_ALL_PIPELINES.find(s => s.id === stageId);
    return stage?.name ?? 'Unknown';
  }

  formatDuration(seconds: number): string {
    if (!seconds || seconds < 0) {
      return '00:00:00';
    }
    const hours = Math.floor(seconds / 3600);
    const minutes = Math.floor((seconds % 3600) / 60);
    const secs = seconds % 60;
  
    const hh = String(hours).padStart(2, '0');
    const mm = String(minutes).padStart(2, '0');
    const ss = String(secs).padStart(2, '0');
  
    return `${hh}:${mm}:${ss}`;
  }
}
