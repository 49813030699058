import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { CoreModule } from '../core/core.module';
import { CommonModule } from '@angular/common';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MatChipsModule } from '@angular/material/chips'
import { MatIconModule } from '@angular/material/icon';
import { MatListModule } from '@angular/material/list';
import { MatTooltipModule } from '@angular/material/tooltip';
import { ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { FileUploadModule } from 'ng2-file-upload';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatNativeDateModule } from '@angular/material/core';
import { TimepickerModule } from 'ngx-bootstrap/timepicker';
import { MatRadioModule } from '@angular/material/radio';
import { MatSelectModule } from '@angular/material/select';
import { SharedModule } from '../shared/shared.module';
import { FieldAddressComponent } from './fields/field-address/field-address.component';
import { FieldCityComponent } from './fields/field-city/field-city.component';
import { FieldCompanyComponent } from './fields/field-company/field-company.component';
import { FieldEmailsComponent } from './fields/field-emails/field-emails.component';
import { FieldGenderComponent } from './fields/field-gender/field-gender.component';
import { EditButtonComponent } from './fields/field-landing/edit-button/edit-button.component';
import { EditInProgressComponent } from './fields/field-landing/edit-in-progress/edit-in-progress.component';
import { FieldIntlTelInputComponent } from './fields/field-intl-tel-input/field-intl-tel-input.component';
import { FieldLinkedinLocationComponent } from './fields/field-linkedin-location/field-linkedin-location.component';
import { FieldLinkedinUrlComponent } from './fields/field-linkedin-url/field-linkedin-url.component';
import { FieldLinkedinUuidComponent } from './fields/field-linkedin-uuid/field-linkedin-uuid.component';
import { FieldNameComponent } from './fields/field-name/field-name.component';
import { FieldNotesComponent } from './fields/field-notes/field-notes.component';
import { FieldPositionComponent } from './fields/field-position/field-position.component';
import { FieldStageIdComponent } from './fields/field-stage-id/field-stage-id.component';
import { FieldStrComponent } from './fields/field-str/field-str.component';
import { FieldUserIdComponent } from './fields/field-user-id/field-user-id.component';
import { FieldIntlTelComponent } from './fields/field-intl-tel/field-intl-tel.component';
import { FieldEmailsInputComponent } from './fields/field-emails-input/field-emails-input.component'
import { CustomContactsModalComponent } from '../shared/custom/custom-contacts-modal/custom-contacts-modal.component';
import { RightDealsPanelComponent } from './right-deals-panel/right-deals-panel.component';
import { NewDealComponent } from './new-deal/new-deal.component';
import { MasterLeadComponent } from './master-lead/master-lead.component';
import { LeftDealsPanelComponent } from './left-deals-panel/left-deals-panel.component';
import { DealsComponent } from './deals.component';
import { EaFollowUpComponent } from './ea-follow-up/ea-follow-up.component';
import { DealCardComponent } from './deal-card/deal-card.component';
import { DealCardContactsComponent } from './deal-card/deal-card-contacts/deal-card-contacts.component';
import { ListDealComponent } from './list-deal/list-deal.component';
import { DealsDetailsComponent } from './deals-details/deals-details.component';
import { WhatsappChatModule } from '../chat/chat.module';
import { ClientCardModule } from '../clients-card/client-card.module';
import { DragulaModule } from 'ng2-dragula';
import { BasketClientCardModalComponent } from './basket-client-card-modal/basket-client-card-modal.component';

@NgModule({
    imports: [
        FormsModule,
        CoreModule,
        CommonModule,
        BrowserAnimationsModule,
        MatChipsModule,
        MatIconModule,
        MatListModule,
        MatTooltipModule,
        ReactiveFormsModule,
        MatButtonModule,
        MatCheckboxModule,
        MatChipsModule,
        MatIconModule,
        MatSlideToggleModule,
        MatAutocompleteModule,
        MatProgressSpinnerModule,
        FileUploadModule,
        MatFormFieldModule,
        MatInputModule,
        MatDatepickerModule,
        MatNativeDateModule,
        TimepickerModule,
        MatRadioModule,
        MatSelectModule,
        SharedModule,
        WhatsappChatModule,
        ClientCardModule,
        DragulaModule.forRoot(),
    ],
    declarations: [
        FieldAddressComponent,
        FieldCityComponent,
        FieldCompanyComponent,
        FieldEmailsComponent,
        FieldGenderComponent,
        EditButtonComponent,
        EditInProgressComponent,
        FieldIntlTelInputComponent,
        FieldLinkedinLocationComponent,
        FieldLinkedinUrlComponent,
        FieldLinkedinUuidComponent,
        FieldNameComponent,
        FieldNotesComponent,
        FieldPositionComponent,
        FieldStageIdComponent,
        FieldStrComponent,
        FieldUserIdComponent,
        FieldIntlTelComponent,
        FieldEmailsInputComponent,
        CustomContactsModalComponent,
        RightDealsPanelComponent,
        NewDealComponent,
        MasterLeadComponent,
        LeftDealsPanelComponent,
        DealsComponent,
        EaFollowUpComponent,
        DealCardComponent,
        DealCardContactsComponent,
        ListDealComponent,
        DealsDetailsComponent,
        BasketClientCardModalComponent
    ],
    exports: [
        FieldAddressComponent,
        FieldCityComponent,
        FieldCompanyComponent,
        FieldEmailsComponent,
        FieldGenderComponent,
        EditButtonComponent,
        EditInProgressComponent,
        FieldIntlTelInputComponent,
        FieldLinkedinLocationComponent,
        FieldLinkedinUrlComponent,
        FieldLinkedinUuidComponent,
        FieldNameComponent,
        FieldNotesComponent,
        FieldPositionComponent,
        FieldStageIdComponent,
        FieldStrComponent,
        FieldUserIdComponent,
        FieldIntlTelComponent,
        FieldEmailsInputComponent,
        CustomContactsModalComponent,
        BasketClientCardModalComponent
    ]
})
export class DealModule { }
