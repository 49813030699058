<div class="row content-wrapper">
  <div class="col-5">
    <div class="sub-title">
      <h6>New User</h6>
    </div>
    <form #form="ngForm">
      @if (isShowForm) {
      <div>
        <div class="field-full-name">
          <pd-field-full-name (fullNameChange)="getFullName($event)"></pd-field-full-name>
        </div>
        <div class="field-email">
          <pd-field-email (emailChange)="getEmail($event)"></pd-field-email>
        </div>
        <div class="field-password">
          <pd-field-password (passwordChange)="getPassword($event)"></pd-field-password>
        </div>
        <div class="field-confirm-password">
          <pd-field-confirm-password (confirmPasswordChange)="getConfirmPassword($event)"></pd-field-confirm-password>
        </div>
        <div class="field-referral-link">
          <pd-field-referral-link (referralLinkChange)="getReferralLink($event)"></pd-field-referral-link>
        </div>
        @if (password.value != confirmPassword.value && confirmPassword.value && password.value) {
        <div class="required-row">
          <span style="color: red">The "Confirm
            Password" field does not match the
            "Password" field.</span>
        </div>
        }
        <div class="field-role">
          <pd-field-role #fieldRole (roleChange)="getRole($event)"></pd-field-role>
        </div> 
        @if (isShowTravelLocal) {

        <div class="field-type-of-clothier">
          <pd-field-type-of-clothier [isLocal]="isLocal" [isTravel]="isTravel" [role]="role"
            (isLocalChange)="getIsLocal($event)" (isTravelChange)="getIsTravel($event)">
          </pd-field-type-of-clothier>
        </div>

        }
        @for (exception of exceptions; track exception) {
        <div class="required-row">
          <p>{{exception}}</p>
        </div>
        }
        <div class="cancel-save-user">
          <button type="button" class="btn btn-danger" (click)="cancelUser()">Cancel</button>
          <button type="button" class="btn btn-success btn-submit" (click)="saveUser()" 
          [disabled]="(!fullName.value || (fullName.value && fullName.value.length === 0))
              || (!email.value || (email.value && email.value.length === 0))
              || (!password.value || (password.value && password.value.length === 0)) || (!confirmPassword.value || (confirmPassword.value && confirmPassword.value.length === 0)) 
              || (password.value != confirmPassword.value && confirmPassword.value && password.value) || !role || !email.isValid || !password.isValid 
              || (isShowTravelLocal && !isTravelLocalIsValid)
              || spinner">
            @if (spinner) {
            <div class="spinner">
              <mat-spinner [diameter]="20"></mat-spinner>
            </div>
            }
            Save user
          </button>
        </div>
      </div>
      }
    </form>
  </div>
</div>

<div class="sticky-wrapper">
  @if (isShowSuccessNewUserAlert) {
  <div class="new-user-alert-wrapper">
    <div class="new-user-alert">
      You’ve created <strong>{{this.newUserFullName}}</strong> user

      <button class="new-user-close-btn close" (click)="isShowSuccessNewUserAlert = false" type="button">
        <span>&times;</span>
      </button>
    </div>
  </div>
  }
</div>