import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { CallEntity } from 'src/app/core/models/CallEntity';
import { CallsService, ElevenLabsConversationDetails } from 'src/app/core/services/calls.service';

@Component({
  selector: 'pd-call-details',
  templateUrl: './call-details.component.html',
  styleUrls: ['./call-details.component.scss']
})
export class CallDetailsComponent implements OnChanges {
  @Input() call: CallEntity | null = null;

  conversationTranscript: ElevenLabsConversationDetails['transcript'] | null = null;
  audioUrl: string | null = null;

  isLoadingConversation = false;
  conversationError: string | null = null;

  isLoadingAudio = false;
  audioError: string | null = null;

  constructor(private callsService: CallsService) { }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['call'] && this.call) {
      this.loadConversationDetails();
      this.loadAudio();
    }
  }

  private async loadConversationDetails() {
    this.conversationTranscript = null;
    this.conversationError = null;

    if (!this.call?.conversationId) {
      this.conversationError = 'No conversationId for this call.';
      return;
    }

    this.isLoadingConversation = true;
    try {
      const details = await this.callsService.getConversationDetails(this.call.conversationId);
      if (details && details.transcript) {
        this.conversationTranscript = details.transcript;
      } else {
        this.conversationError = 'No conversation transcript found.';
      }
    } catch (err) {
      console.error('Error loading conversation details', err);
      this.conversationError = 'Error loading conversation details.';
    } finally {
      this.isLoadingConversation = false;
    }
  }

  private async loadAudio() {
    this.audioUrl = null;
    this.audioError = null;

    if (!this.call?.conversationId) {
      this.audioError = 'No conversationId for this call.';
      return;
    }

    this.isLoadingAudio = true;
    try {
      const audioBlob = await this.callsService.getAudio(this.call.conversationId);
      if (audioBlob) {
        this.audioUrl = URL.createObjectURL(audioBlob);
      } else {
        this.audioError = 'No audio found.';
      }
    } catch (err) {
      console.error('Error loading audio', err);
      this.audioError = 'Error loading audio.';
    } finally {
      this.isLoadingAudio = false;
    }
  }
}
