import { Component, EventEmitter, Input, OnInit, Output, QueryList, ViewChildren } from "@angular/core";
import { FieldEmailsInputComponent } from "../field-emails-input/field-emails-input.component";
import { ContactType } from "src/app/core/enums/ContactType";

interface EmailModel {
  value: string;
  label: string;
  newValue?: string;
  contactTypeId: ContactType;
}

@Component({
  selector: 'pd-field-emails',
  templateUrl: './field-emails.component.html',
  styleUrls: ['./field-emails.component.scss']
})
export class FieldEmailsComponent implements OnInit {
  @ViewChildren('inputFields') inputFields: QueryList<FieldEmailsInputComponent>;

  @Input() emails: EmailModel[] = [];
  @Input() isNeedSaveCancelBtn = true;

  @Output() emailsSave = new EventEmitter<EmailModel[]>();
  @Output() emailsCancel = new EventEmitter<EmailModel[]>();

  public isEdit = false;
  public prevEmails: EmailModel[] = [];
  public readonly emailLabels = ['Work', 'Home', 'Other'];
  public duplicateError = false;

  ngOnInit() {
    this.prevEmails = this.deepClone(this.emails);
    this.isEdit = true;
  }

  public onEmailChange(email: EmailModel, updatedEmailModel: { email: string }) {
    email.newValue = updatedEmailModel.email.toLowerCase();
  }

  public addOneMore() {
    this.emails.push({ value: '', label: 'Work', contactTypeId: ContactType.Email });
  }

  public deleteEmail(index: number) {
    this.emails.splice(index, 1);
  }

  public save() {
    if (!this.isAllValidCheck()) {
      return;
    }

    this.isEdit = false;

    let updatedEmails = this.getEmails();

    updatedEmails = updatedEmails.filter(email => !!email.value);

    this.emails = updatedEmails;
    this.prevEmails = this.deepClone(updatedEmails);

    this.emailsSave.emit(updatedEmails);
  }

  public getEmails(): EmailModel[] {
    this.emails.forEach((email) => {
      if (email.newValue !== undefined) {
        email.value = email.newValue;
      }
    });
    this.prevEmails = this.deepClone(this.emails);
    return this.emails;
  }

  public cancel() {
    this.isEdit = false;
    this.emails = this.deepClone(this.prevEmails);
    this.emailsCancel.emit(this.emails);
  }

  public trackBy(index: number): number {
    return index;
  }

  private deepClone(array: EmailModel[]): EmailModel[] {
    return array.map(item => ({ ...item }));
  }

  public isAllValidCheck(): boolean {
    return this.areAllEmailsFieldsValid() && !this.hasDuplicateEmails();
  }

  private areAllEmailsFieldsValid(): boolean {
    return !(this.inputFields && this.inputFields.some((input) => !input.checkIsValid()));
  }

  private hasDuplicateEmails(): boolean {
    const updatedEmails = this.emails.map(email => email.newValue !== undefined ? { ...email, value: email.newValue } : { ...email });
    const values = updatedEmails.map(e => e.value).filter(v => !!v);
    this.duplicateError = values.some((val, idx) => values.indexOf(val) !== idx);
    return this.duplicateError;
  }
}
