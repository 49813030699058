import { Injectable } from '@angular/core';
import { MatSnackBar, MatSnackBarConfig } from '@angular/material/snack-bar';
import { CustomSnackbarComponent } from 'src/app/shared/custom-snackbar/custom-snackbar.component';

@Injectable()
export class NotificationService {
  constructor(private snackBar: MatSnackBar) {}

  showSuccess(message: string): void {
    this.openSnackBar(message, 'success');
  }

  showError(message: string): void {
    this.openSnackBar(message, 'error');
  }

  private openSnackBar(message: string, type: 'error' | 'success'): void {
    const config: MatSnackBarConfig = {
      data: { message, type },
      duration: 3000,
      horizontalPosition: 'center',
      verticalPosition: 'bottom',
      panelClass: ['custom-snackbar', type],
    };

    this.snackBar.openFromComponent(CustomSnackbarComponent, config);
  }
}