import { Injectable } from '@angular/core';
import { HttpRestService } from '../http-client/http-rest-client.service';
import { BaseService } from '../base.service';
import { Message } from '../../models/chat-models/Message';
import { Chat } from '../../models/chat-models/Chat';

@Injectable()
export class TwilioApiService extends BaseService {
    constructor(private restService: HttpRestService) {
        super();
    }

    public async getChats(filter): Promise<Chat[]> {
        return await this.handleResponse(this.restService.post({ url: '/twilio/getChats', data: filter }));
    }

    public async getMessagesByChatId(chatId: string): Promise<Message[]> {
        return await this.handleResponse(this.restService.post({ url: '/twilio/getMessagesByChatId', data: { chatId } }));
    }

    public async sendWhatsappMessage(dealId: number, toPhone: string, message: string, file): Promise<any> {
        let formData: FormData = new FormData();
        if (file) {
            formData.append('file', file, file.name);
        }
        formData.append('toPhone', toPhone);
        formData.append('message', message);

        return await this.handleResponse(this.restService.upload(
            {
                url: '/twilio/sendWhatsappMessage/' + dealId,
                data: formData
            }));
    }

    public async sendSmsMessage(dealId: number, toPhone: string, message: string, file): Promise<any> {
        let formData: FormData = new FormData();
        if (file) {
            formData.append('file', file, file.name);
        }
        formData.append('toPhone', toPhone);
        formData.append('message', message);
        return await this.handleResponse(this.restService.upload(
            {
                url: '/twilio/sendSmsMessage/' + dealId,
                data: formData
            }));
    }
}
