<div class="chat-selector">
    @if (isGlobalChat) {
    <div class="chat-filter-wrapper">
        <div class="chat-filter">
            <input #searchInput (change)="doSearch()" (keyup)="doSearch()" placeholder="Search chats..." />
            @if(!isRecruiterAccount)
            {
            <mat-form-field class="conversation-type-select conversation-type-select-global">
                <mat-select [(value)]="selectedConversationType" (selectionChange)="doConversationType()">
                    @for (conversationType of conversationTypeList; track conversationType) {
                    <mat-option [value]="conversationType">{{EnumUtil.getEnumName(ChatConversationTypes,
                        conversationType)}}</mat-option>
                    }
                </mat-select>
            </mat-form-field>
            }
        </div>
    </div>
    }
    <div (scroll)="doLazyLoading($event)"
        [ngClass]="{'chat-list-wrapper': !isGlobalChat, 'chat-list-wrapper--global': isGlobalChat && !isRecruiterAccount, 'chat-list-wrapper--recruiter': isRecruiterAccount}">
        <div #targetScroll></div>
        <ul class="chat-list">
            @for (chat of chatList; track chat) {
            <li [class.active-chat]="chat && activeChat && chat.id === activeChat.id && chat.dealId == activeChat.dealId" (click)="setActiveChat(chat)">
                <div class="chat-info">
                    <div class="name">
                        @if (!chat.isWhatsApp) {
                        <img src='../../assets/sms.png' class="chat-type-icon" />
                        }
                        @if (chat.isWhatsApp) {
                        <img src='../../assets/whatsapp.png' class="chat-type-icon" />
                        }
                        {{ chat.title ? chat.title : chat.chatPhone}}
                    </div>
                    @if(chat.title) {
                    <ng-container>
                        <div class="number">{{ chat.chatPhone }}
                        </div>
                        @if(chat.isDeleted)
                        {
                        <div class="deleted-label">Deleted Number</div>
                        }
                    </ng-container>
                    }
                    @if (chat.lastMessage) {
                    <div class="text">
                        @if (chat.lastMessage.isInboundMessage) {
                        <span class="client-label">Client: </span>
                        }
                        @if (chat.lastMessage.medias && chat.lastMessage.medias.length > 0) {
                        <div class="media-preview">
                            @for (media of chat.lastMessage.medias; track media) {

                            @if (FileUtil.isVideo(media.contentType)) {
                            <div>Video</div>
                            }
                            @if (FileUtil.isAudio(media.contentType)) {
                            <div>Audio</div>
                            }
                            @if (FileUtil.isImage(media.contentType)) {
                            <div>Image</div>
                            }

                            }
                        </div>
                        }
                        @if(TwilioHelper.isReactionMessage(chat.lastMessage))
                        {
                        <i>{{TwilioHelper.textForReactionMessage}}</i>
                        }
                        @if(!TwilioHelper.isReactionMessage(chat.lastMessage))
                        {
                        {{chat.lastMessage.text}}
                        }
                    </div>
                    }
                    @if (chat.lastMessage) {
                    <div>
                        <div class="time">{{DateHelper.utcStringToLocalDate(chat.lastMessage.time) | date : dateFormat}}
                        </div>
                        @if (!chat.lastMessage.isInboundMessage) {
                        <div class="icon-wrapper" bootstrapTooltip={{getErrorMessageTooltip(chat.lastMessage)}}>
                            <mat-icon [svgIcon]="TwilioHelper.getMessageIcon(chat.lastMessage)"
                                [ngClass]="TwilioHelper.getMessageIconClass(chat.lastMessage)" class="icon">
                            </mat-icon>
                        </div>
                        }
                    </div>
                    }
                </div>
            </li>
            }
        </ul>
    </div>
</div>