import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { MatSelectModule } from '@angular/material/select';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { InterceptorService } from './core/services/interseptor.service';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { LoginComponent } from './login/login.component';
import { CoreModule } from './core/core.module';
import { SharedModule } from './shared/shared.module';
import { ClientCounterSettingsComponent } from './client-counter-settings/client-counter-settings.component';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatRadioModule } from '@angular/material/radio';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { PaymentComponent } from './payment/payment.component';
import { StripeModalComponent } from './payment/stripe-modal/stripe-modal.component';
import { UploadingComponent } from './uploading/uploading.component';
import { GlobalSearchComponent } from './global-search/global-search.component';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from "@angular/material/form-field";
import { HotlistComponent } from './hotlist/hotlist.component';
import { UsersModule } from './users/users.module';
import { MatTooltipModule } from '@angular/material/tooltip';
import { TasksModule } from './tasks/tasks.module';
import { ClothierToEaConnectionComponent } from './clothier-to-ea-connection/clothier-to-ea-connection.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MatIconModule } from '@angular/material/icon';
import { DealModule } from './deals/deals.module';
import { GeocodeNearbyDealsComponent } from './geocode-nearby-deals/geocode-nearby-deals.component';
import { BaseComponent } from './core/base.component';
import { AvatarSeasonalProposalModule } from './avatar-seasonal-proposal/avatar-seasonal-proposal.module';
import { WhatsappChatModule } from './chat/chat.module';
import { ClientCardModule } from './clients-card/client-card.module';
import { AchievementsCardModule } from './achievements-card/achievements.module';
import { Modeler3dProductComponentModule } from './3d-modeler-product/3d-modeler-product.module';
import { UserRequestPanelModule } from './user-request-panel/user-request-panel.module';
import { HotlistDownloadComponent } from './hotlist-download/hotlist-download.component';
import { ReportsComponent } from './tasks/reports/reports.component';
import { PaymentResultComponent } from './payment-result/payment-result.component';
import { BulkMessageModule } from './bulk-message-settings/bulk-message-settings.module';
import { CallModule } from './calls/call.module';

@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    ClientCounterSettingsComponent,
    PaymentComponent,
    StripeModalComponent,
    UploadingComponent,
    GlobalSearchComponent,
    HotlistComponent,
    ClothierToEaConnectionComponent,
    GeocodeNearbyDealsComponent,
    HotlistDownloadComponent,
    ReportsComponent,
    PaymentResultComponent,
    BaseComponent,
  ],
  imports: [
    FormsModule,
    ReactiveFormsModule,
    BrowserModule,
    AppRoutingModule,
    CoreModule,
    SharedModule,
    MatSelectModule,
    MatCheckboxModule,
    MatRadioModule,
    MatProgressSpinnerModule,
    MatFormFieldModule,
    MatInputModule,
    UsersModule,
    TasksModule,
    MatTooltipModule,
    BrowserAnimationsModule,
    MatIconModule,
    DealModule,
    AvatarSeasonalProposalModule,
    WhatsappChatModule,
    ClientCardModule,
    AchievementsCardModule,
    Modeler3dProductComponentModule,
    UserRequestPanelModule,
    BulkMessageModule,
    CallModule
  ],
  providers: [{ provide: HTTP_INTERCEPTORS, useClass: InterceptorService, multi: true }],
  bootstrap: [AppComponent]
})
export class AppModule { }