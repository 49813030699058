import { HttpRestService } from './http-client/http-rest-client.service';
import { Injectable } from '@angular/core';
import { Observable, from } from 'rxjs'
import { BaseService } from './base.service';
import { Person } from '../models/Person';
import { DealDetails } from '../models/DealDetails';
import { FilteredByContactModel, FilteredDealsModel } from '../models/Deal';
import { DealPersonMap } from '../models/DealPersonMap';
import { UtilsHelper } from '../utils/utils.helper';
import { Mapper } from '../utils/mapper.util';
import { CommonConnections } from '../models/Participant';
import { SameBuildingConnections } from '../models/SameBuilding';
import { HistoryRecord } from '../models/DealHistory';
import { DateHelper } from '../utils/date.helper';
import { FilterType } from '../enums/FilterType.enum';
import { BaseFilterModel } from '../models/filter-models/BaseFilterModel.model';
import { FilterDataAutocompleteModel } from '../models/filter-models/FilterDataAutocompleteModel';
import { ClientGroup } from '../models/ClientGroup';
import { DealCountByClientRating } from '../models/DealCountByClientRating';
import { ExportDealsType } from '../enums/ExportDealsType';
import { saveAs } from 'file-saver'
import { PersonPropertiesToUpdate } from '../enums/PersonPropertiesToUpdate';

/**
 * Deal service
 * contains methods connected with deals
 * @export
 * @class DealService
 */
@Injectable()
export class DealService extends BaseService {
  public UpdateStageConflictMessage = "The status of the deal was updated before in another tab. Please make sure that you need to update the status of the deal. Thank you";

  constructor(private restService: HttpRestService) {
    super();
  }

  /**
   * Get deals
   * @param {number} pipeline tables value
   * @param {number} start from which number of person to start
   * @param {number} limit number of persons
   * @param {number} filterUserId user id of selected user
   * @param {string} gender selected gender
   * @param {string[]} locations selected locations
   * @param {number} stageId selected stage
   * @returns {Promise<FilteredDealsModel>} pipedrive deal promise response
   * @memberof DealService
   */

  public async getDeals(pipeline: number, start: number, limit: number, filterUserIds: number[], gender: number, locations: any,
    stageId: number = null,
    isContactByEmailOnlyFilter: boolean = false,
    isSameBuildingFilter: boolean = false,
    isWithPhoneNumberOnlyFilter: boolean = false,
    companies?: any, positions?: any,
    clientGroupId: number = null): Promise<FilteredDealsModel> {
    if (clientGroupId === -1) {
      clientGroupId = null;
    }
    const data = {
      Start: start,
      Limit: limit,
      FilterUserIds: filterUserIds,
      Locations: locations,
      Positions: positions,
      Companies: companies,
      GenderId: gender,
      PipelineId: pipeline,
      StageId: stageId,
      isContactByEmailOnlyFilter: isContactByEmailOnlyFilter,
      isSameBuildingFilter: isSameBuildingFilter,
      isWithPhoneNumberOnlyFilter: isWithPhoneNumberOnlyFilter,
      ClientGroup: clientGroupId
    };
    var result = await this.handleResponse(this.restService.post({
      url: '/test/deals',
      data
    }));

    return this.createFilteredDealResult(result);
  }

  /**
 * Get persons whose birthday is today
 * @returns { Promise<Array<Person>>} response with persons
 * @memberof DealService
 */

  public async getPersonsBirthdayToday(): Promise<Array<Person>> {
    return await this.handleResponse(this.restService.get({
      url: '/person/getPersonsBirthdayToday'
    }));
  }

  public async getDealsNearByDealId(dealId, km): Promise<Array<DealPersonMap>> {
    return await this.handleResponse(this.restService.get({
      url: `/test/persons/GetDealsNearByDealId?dealId=${dealId}&km=${km}`
    }));
  }

  public async getDealsNear(data): Promise<Array<DealPersonMap>> {
    return await this.handleResponse(this.restService.post({
      url: '/test/persons/getDealsNear',
      data: data
    }));
  }

  public async updateProspectsGeocode(data): Promise<Array<DealPersonMap>> {
    return await this.handleResponse(this.restService.post({
      url: '/test/persons/updateProspectsGeocode',
      data
    }));
  }

  /**
   * Get deals by search
   * @param {string} term searct text
   * @param {number} start from which number of person to start
   * @param {number} limit number of persons
   * @returns {Promise<FilteredDealsModel>} pipedrive deal promise response
   * @memberof DealService
   */

  public async getDealsBySearch(data): Promise<FilteredDealsModel> {
    var result = await this.handleResponse(this.restService.post({
      url: '/test/deal/searchDeal',
      data
    }));

    return this.createFilteredDealResult(result);
  }

  /**
     * Get person by id
     * @param {any} id person id
     * @returns {Promise<Person>} pipedrive deal promise response with person id
     * @memberof DealService
     */
  // [Obsolete]

  public async getPersonById(id: any): Promise<Person> {
    return await this.handleResponse(this.restService.get({
      url: '/person/' + id
    }));
  }

  /**
     * Update stage
     * for dragula
     * @param {any} dealId deal id
     * @param {any} stageId stage id
     * @param {any} previousStageId previous stage id
     * @returns {Promise<Deal>} pipedrive deal promise response with deal id and stage id
     * @memberof DealService
     */

  public async updateStage(dealId: any, stageId: any, previousLabeledStageId: number, previousStageId: any, isDashboardView: boolean
    , meeting_date, meeting_date_utc, meeting_time, ownerId?: number
    , customRottenTime?: Date, isNrContactedRepeat?: boolean): Promise<any> {
    const data = {
      Id: dealId,
      StageId: stageId,
      PreviousLabeledStageId: previousLabeledStageId,
      PreviousStageId: previousStageId,
      IsDashboardView: isDashboardView,
      Meeting_date: meeting_date,
      Meeting_date_utc: meeting_date_utc,
      Meeting_time: meeting_time,
      OwnerId: ownerId,
      CustomRottenTime: customRottenTime,
      IsNrContactedRepeat: isNrContactedRepeat
    };

    return await this.restService.post({
      url: '/test/deal/stage',
      data
    });
  }

  public async updateInformation(personId: any, propertyId: PersonPropertiesToUpdate, value: any, dealId: any): Promise<any> {
    const data = {
      deal_id: dealId,
      person_id: personId,
      property_id: propertyId,
      value: value
    };
    return await this.restService.post({
      url: '/test/deal/person',
      data
    });
  }

  public async getPartnerInfoLink(personId: number): Promise<any> {
    const url = '/test/deal/person/partnerInfoLink/' + personId;
    return await this.handleResponse(this.restService.get({ url }));
  }

  /**
  * Update information
  * @param {any} person_id person id
  * @param {address} name person company address
  * @param {homeAddress} name person home address
  * @param {any} latitude person company address latitude
  * @param {any} longitude person company address longitude
  * @memberof DealService
  */

  public async updatePersonAddressesAndGeocode(
    person_id: any,
    address: string,
    homeAddress: string,
    company: string,
    location: string,
    latitude: any,
    longitude: any
  ): Promise<any> {
    const data = {
      id: person_id,
      address: UtilsHelper.addressNormalize(address),
      homeAddress: UtilsHelper.addressNormalize(homeAddress),
      company: company,
      location: location,
      latitude: latitude,
      longitude: longitude
    };

    return await this.restService.post({
      url: '/test/deal/personAddressesAndGeocode',
      data
    });
  }

  /**
  * Get Address By CompanyName From Google Api
  * @param {any} person_id person id
  * @param {string} companyName property companyName
  * @param {string} location property location
  * @memberof DealService
  */

  public async getAddressByCompanyName(person_id: any, companyName: string, location: string): Promise<any> {
    const data = {
      id: person_id,
      company: companyName,
      location: location,
    };

    return await this.handleResponse(this.restService.post({
      url: '/test/deal/getAddressByCompanyName',
      data
    }));
  }

  /**
 * Get deal by id
 * @param {any} id deal id
 * @returns {Promise<Deal>} pipedrive deal promise response with deal id
 * @memberof DealService
 */

  public async getDealById(id: any): Promise<DealDetails> {
    const result = await this.handleResponse(this.restService.get({
      url: '/test/deal/' + id
    }));

    return Mapper.transformDealDetailsToClient(result);
  }

  /**
   * Get participants by deal_id
   * @param {any} dealId deal id
   * @param {any} personId person id
   * @returns {Promise<Participant[]>} pipedrive deal promise response with deal id
   * @memberof DealService
   */

  public async getParticipants(dealId: any, personId: any): Promise<CommonConnections> {
    return await this.handleResponse(this.restService.get({
      url: `/test/deal/${dealId}/participants`
    }));
  }

  /**
 * Get sameBuildings by person_Id
 * @param {any} personId person Id
 * @returns {Promise<SameBuildings[]>} pipedrive deal promise response with deal id
 * @memberof DealService
 */

  public async getSameBuildings(personId: any): Promise<SameBuildingConnections> {
    return await this.handleResponse(this.restService.get({
      url: `/test/deal/${personId}/sameBuildings`
    }));
  }

  /**
 * Get getDealHistory by deal_Id
 * @param {any} dealId dealId
 * @returns {Promise<Array<HistoryRecord>>} pipedrive deal promise response with deal id
 * @memberof DealService
 */

  public async getDealHistory(dealId: any): Promise<Array<HistoryRecord>> {
    return await this.handleResponse(this.restService.get({
      url: `/deal/getDealHistory?dealId=${dealId}`
    }));
  }

  /**
   * Add deal
   * @param {any} deal new deal
   * @returns {Promise<any>} pipedrive deal promise response with deal id
   * @memberof DealService
   */

  public async addDeal(deal: any): Promise<any> {
    return await this.handleResponse(this.restService.post({
      url: '/test/deal/add',
      data: deal
    }));
  }

  /**
   * Get list deal
   * @param {number} start from which number of person to start
   * @param {number} limit number of persons
   * @param {any} sortBy value of sorting
   * @param {any} userId user id
   * @param {any} location user location
   * @param {any} gender user gender
   * @param {any} stage user stage
   * @returns {Promise<FilteredDealsModel>} pipedrive deal promise response with deal id
   * @memberof DealService
   */

  public async getListDeal(start: number, limit: number, sortBy: any,
    userIds: any, location: any, gender: any, stages: any, companies?: any, positions?: any, isSameBuildingFilter?: boolean, isWithPhoneNumberOnlyFilter?: boolean): Promise<FilteredDealsModel> {
    const data = this.getListDealFilter(start, limit, sortBy, userIds, location, gender, stages, companies, positions, isSameBuildingFilter, isWithPhoneNumberOnlyFilter);
    const result = await this.handleResponse(this.restService.post({
      url: '/test/deals/list',
      data
    }));

    return this.createFilteredDealResult(result);
  }

  /**
   * Get list deal
   * @param {number} start from which number of person to start
   * @param {number} limit number of persons
   * @param {any} userId user id
   * @param {any} location user location
   * @param {any} gender user gender
   * @param {any} stage user stage
   * @returns {Promise<FilteredDealsModel>} pipedrive deal promise response with deal id
   * @memberof DealService
   */

  public async getExportListDealWithContactInfo(
    start: number
    , limit: number
    , userIds: any
    , location: any
    , gender: any
    , stages: any
    , companies?: any
    , positions?: any
    , isSameBuildingFilter?: boolean
    , isWithPhoneNumberOnlyFilter?: boolean
    , lastExportDealDateFrom?: any
    , lastExportDealDateTo?: any
    , isIncludeExportedLeads?: boolean
    , isIncludeUnexportedLeads?: boolean
  ): Promise<FilteredDealsModel> {

    const data = this.getListDealFilter(
      start
      , limit
      , null
      , userIds
      , location
      , gender
      , stages
      , companies
      , positions
      , isSameBuildingFilter
      , isWithPhoneNumberOnlyFilter
      , (lastExportDealDateFrom) ? DateHelper.formatAsISODateString(lastExportDealDateFrom) : lastExportDealDateFrom
      , (lastExportDealDateTo) ? DateHelper.formatAsISODateString(lastExportDealDateTo) : lastExportDealDateTo
      , isIncludeExportedLeads
      , isIncludeUnexportedLeads
    );

    const result = await this.handleResponse(this.restService.post({
      url: '/test/deals/export-list-with-contact-info',
      data
    }));

    return this.createFilteredDealResult(result);
  }

  private getListDealFilter(start: number, limit: number, sortBy: any, userIds: any, location: any, gender: any, stages: any,
    companies?: any[], positions?: any, isSameBuildingFilter?: boolean, isWithPhoneNumberOnlyFilter?: boolean,
    lastExportDealDateFrom?: any, lastExportDealDateTo?: any, isIncludeExportedLeads?: boolean, isIncludeUnexportedLeads?: boolean): any {
    userIds = userIds.filter(id => id !== -1);
    return {
      Start: start ? start : 0,
      Limit: limit,
      SortBy: sortBy,
      FilterUserIds: userIds,
      Locations: location,
      Positions: positions,
      Companies: companies,
      GenderId: gender,
      StageIds: stages,
      isSameBuildingFilter: isSameBuildingFilter,
      isWithPhoneNumberOnlyFilter: isWithPhoneNumberOnlyFilter,
      lastExportDealDateFrom: lastExportDealDateFrom,
      lastExportDealDateTo: lastExportDealDateTo,
      isIncludeExportedLeads: isIncludeExportedLeads,
      isIncludeUnexportedLeads: isIncludeUnexportedLeads
    };
  }

  public async findEmails(start: number, limit: number, sortBy: any, userIds: any, location: any, gender: any, stages: any,
    companies?: any, positions?: any, isSameBuildingFilter?: boolean, isWithPhoneNumberOnlyFilter?: boolean): Promise<any> {
    const data = this.getListDealFilter(start, limit, sortBy, userIds, location, gender, stages, companies, positions, isSameBuildingFilter, isWithPhoneNumberOnlyFilter);

    return this.handleResponse(this.restService.post({
      url: '/test/deals/findEmails',
      data
    }));
  }


  public async exportEmails(start: number, limit: number, sortBy: any, userIds: any, location: any, gender: any, stages: any,
    companies?: any, positions?: any, isSameBuildingFilter?: boolean, isWithPhoneNumberOnlyFilter?: boolean): Promise<any> {
    const data = this.getListDealFilter(start, limit, sortBy, userIds, location, gender, stages, companies, positions, isSameBuildingFilter, isWithPhoneNumberOnlyFilter);
    return this.handleResponse(this.restService.post({
      url: '/test/deals/exportEmails',
      data
    }));
  }

  /**
   * Update owner deals
   * @param {number} ids deal ids
   * @param {number} userId user id
   * @returns {Promise<any>} pipedrive deal promise response with deal id
   * @memberof DealService
   */

  public async updateOwnerDeals(ids: any, userId: any): Promise<any> {
    const data = {
      deal_ids: ids,
      user_id: userId
    };

    return this.handleResponse(this.restService.post({
      url: '/test/deal/owner',
      data
    }));
  }

  /**
   * Get list deal
   * @param {number} start from which number of person to start
   * @param {number} limit number of persons
   * @param {any} filterUserid selected user id
   * @param {any} location user location
   * @param {any} gender user gender
   * @param {any} ownerId owner id
   * @param {number} stageToId transfer to stage id
   * @returns {Promise<any>} pipedrive deal promise response with deal id
   * @memberof DealService
   */

  public async updateOwnerFilteredDeal(transferFilterModel: any): Promise<any> {
    const data = {
      FilterUserIds: transferFilterModel.filterUserIds,
      GenderId: transferFilterModel.genderId,
      Locations: transferFilterModel.locations,
      Positions: transferFilterModel.positions,
      Companies: transferFilterModel.companies,
      StageIds: transferFilterModel.stageIds,
      Owners: transferFilterModel.owners,
      IsSameBuildingFilter: transferFilterModel.isSameBuildingFilter,
      IsWithPhoneNumberOnlyFilter: transferFilterModel.isWithPhoneNumberOnlyFilter
    };

    return this.handleResponse(this.restService.post({
      url: '/test/deals/transfer',
      data
    }));
  }

  /**
   * Get master lead deals
   * @param {number} start from which number of person to start
   * @param {number} limit number of persons
   * @returns {Promise<FilteredDealsModel>} pipedrive deal promise response with deal id
   * @memberof DealService
   */

  public async getMasterLeadDeals(start: number, limit: number): Promise<FilteredDealsModel> {
    const result = await this.handleResponse(this.restService.get({
      url: `/test/deals/master?start=${start}+&limit=${limit}`
    }));
    result.data.forEach(element => {
      element.stage_id = element.stageId;
    });

    return result;
  }

  public async moveThisLeadToMe(dealId: number, userId: number): Promise<any> {
    const data = {
      dealId: dealId,
      userId: userId,
    };

    return this.handleResponse(this.restService.post({
      url: '/test/deal/moveThisLeadToMe',
      data
    }));
  }

  public async updateUsingCommonConnection(personId: number, dealId: number, value: boolean): Promise<any> {
    const data = {
      deal_id: dealId,
      person_id: personId,
      property_id: PersonPropertiesToUpdate.CommonConnection,
      value: value
    };

    return await this.handleResponse(this.restService.post({
      url: '/test/deal/person',
      data
    }));
  }

  public async updateSendPromotionalEmails(personId: number, dealId: number, value: boolean): Promise<any> {
    const data = {
      deal_id: dealId,
      person_id: personId,
      property_id: PersonPropertiesToUpdate.ForbidPromotionalEmails,
      value: value
    };
    return await this.handleResponse(this.restService.post({
      url: '/test/deal/person',
      data
    }));
  }

  public async updateSendPartnerEmails(personId: number, dealId: number, value: boolean): Promise<any> {
    const data = {
      deal_id: dealId,
      person_id: personId,
      property_id: PersonPropertiesToUpdate.ForbidPartnerEmails,
      value: value
    };
    return await this.handleResponse(this.restService.post({
      url: '/test/deal/person',
      data
    }));
  }

  public async updateIsPartnerRepliedToBDayEmail(personId: number, dealId: number, value: boolean): Promise<any> {
    const data = {
      deal_id: dealId,
      person_id: personId,
      property_id: PersonPropertiesToUpdate.IsPartnerRepliedToBDayEmail,
      value: value
    };
    return await this.handleResponse(this.restService.post({
      url: '/test/deal/person',
      data
    }));
  }

  public async updateSendWhatsapp(personId: number, dealId: number, value: boolean): Promise<any> {
    const data = {
      deal_id: dealId,
      person_id: personId,
      property_id: PersonPropertiesToUpdate.SendWhatsapp,
      value: value
    };

    return await this.handleResponse(this.restService.post({
      url: '/test/deal/person',
      data
    }));
  }

  public async updateSendSms(personId: number, dealId: number, value: boolean): Promise<any> {
    const data = {
      deal_id: dealId,
      person_id: personId,
      property_id: PersonPropertiesToUpdate.SendSms,
      value: value
    };

    return await this.handleResponse(this.restService.post({
      url: '/test/deal/person',
      data
    }));
  }

  public async getDealsBySearchContact(term: any, start: number, limit: number): Promise<FilteredByContactModel> {
    const result = await this.handleResponse(this.restService.get({
      url: `/test/deal/searchByContact?term=${term}&start=${start}&limit=${limit}`
    }));

    return this.createFilteredContactResult(result);
  }

  public async moveToMeetingStage(dealId: number, previousStageId: number, stageId: number, userId: number,
    isDashboardView: boolean, meeting_date, meeting_date_utc, meeting_time,
  ): Promise<any> {
    const data = {
      id: dealId,
      PreviousStageId: previousStageId,
      PreviousLabeledStageId: null,
      StageId: stageId,
      UserId: userId,
      IsDashboardView: isDashboardView,
      Meeting_date: meeting_date,
      Meeting_date_utc: meeting_date_utc,
      Meeting_time: meeting_time
    };

    return this.handleResponse(this.restService.post({
      url: '/test/deal/toMeeting',
      data
    }));
  }

  /**
   * Get campaign deals
   * @param {number} pipeline tables value
   * @param {number} start from which number of person to start
   * @param {number} limit number of persons
   * @param {number} filterUserId user id of selected user
   * @param {string} gender selected gender
   * @param {string[]} locations selected locations
   * @returns {Promise<FilteredDealsModel>} pipedrive deal promise response
   * @memberof DealService
   */

  public async getCampaignDeals(pipeline: number, start: number, limit: number, filterUserIds: number[], gender: number, locations: any,
    companies?: any, positions?: any, clientGroupId: number = null): Promise<FilteredDealsModel> {
    if (clientGroupId === -1) {
      clientGroupId = null;
    }

    const data = {
      Start: start,
      Limit: limit,
      FilterUserIds: filterUserIds,
      Locations: locations,
      Companies: companies,
      Positions: positions,
      GenderId: gender,
      PipelineId: pipeline,
      ClientGroup: clientGroupId
    }
    const result = await this.handleResponse(this.restService.post({
      url: '/test/deals/campaign',
      data
    }));

    return this.createFilteredDealResult(result);
  }

  public async getMissedMtgDeals(pipeline: number, filterUserIds: number[], start: number, limit: number, gender: number, locations: any,
    companies?: any, positions?: any, clientGroupId: number = null): Promise<FilteredDealsModel> {
    if (clientGroupId === -1) {
      clientGroupId = null;
    }

    const data = {
      Start: start,
      Limit: limit,
      Locations: locations,
      Companies: companies,
      Positions: positions,
      GenderId: gender,
      FilterUserIds: filterUserIds,
      PipelineId: pipeline,
      ClientGroup: clientGroupId,
    };
    const result = await this.handleResponse(this.restService.post({
      url: '/test/deals/missed',
      data
    }));

    return this.createFilteredDealResult(result);
  }

  public async updatePersonWhoReferred(dealId, personWhoReferred): Promise<any> {
    const data = {
      DealId: dealId,
      personWhoReferred: personWhoReferred
    };
    return this.handleResponse(this.restService.post({
      url: '/deal/personWhoReferred',
      data
    }));
  }

  public async deleteDeal(dealId): Promise<any> {
    const data = {
      DealId: dealId
    };
    return this.handleResponse(this.restService.post({
      url: '/deal/deleteDeal',
      data
    }));
  }

  /**
     * Check rules from deal history
     * @param {number} dealId deal Id
     * @param {number} newStage new stage Id
     * @returns {Promise<any>} status and message of check
     * @memberof DealService
     */

  public checkExtraDealMoveRules(dealId: number, newStage: number) {
    const data = {
      DealId: dealId,
      NewStage: newStage
    };
    return this.handleResponse(this.restService.post({
      url: '/deal/checkExtraDealMoveRules',
      data
    }));
  }

  public async getFilterData(filterType: FilterType, skip: number, take: number): Promise<BaseFilterModel[]> {
    const data = {
      FilterType: filterType,
      Skip: skip,
      Take: take
    };
    return await this.handleResponse(this.restService.post({
      url: '/test/deals/getFilterData',
      data
    }));
  }

  public async getFilterDataAutocomplete(filterType: FilterType, searchString: string, skip: number, take: number): Promise<BaseFilterModel[]> {
    const data = {
      FilterType: filterType,
      SearchString: searchString,
      Skip: skip,
      Take: take
    };
    return await this.handleResponse(this.restService.post({
      url: '/test/deals/getFilterDataAutocomplete',
      data
    }));
  }

  public async getFilterDataAutocomplete2(data: FilterDataAutocompleteModel): Promise<any[]> {
    return await this.handleResponse(this.restService.post({
      url: '/test/deals/getFilterDataAutocomplete',
      data
    }));
  }

  public async getClientGroups(): Promise<ClientGroup[]> {
    return await this.handleResponse(this.restService.get({
      url: '/test/deal/getClientGroups'
    }));
  }

  public async getDealLocalTimeZone(dealId: number): Promise<string> {
    return await this.handleResponse(this.restService.get({
      url: `/deal/getDealLocalTimeZone/${dealId}`
    }));
  }

  public async hasAnyChats(dealId: number): Promise<boolean> {
    return await this.handleResponse(this.restService.get({
      url: `/deal/hasAnyChats/${dealId}`
    }));
  }

  public async getClientRatingDealCount(filterUserIds: any, locations: any): Promise<Array<DealCountByClientRating>> {
    const data = {
      FilterUserIds: filterUserIds,
      Locations: locations,
    };
    const url = '/test/deal/clientRatingDealCount';

    return await this.handleResponse(this.restService.post({ url, data }));
  }

  public async updateClientGroup(id: any, clientGroup: any): Promise<any> {
    const data = {
      DealId: id,
      ClientGroup: clientGroup
    }
    return await this.handleResponse(this.restService.post({
      url: '/deal/updateClientGroup',
      data
    }));
  }

  public async updateCalled(id: number, called: boolean): Promise<any> {
    const data = {
      DealId: id,
      Called: called
    }
    return await this.handleResponse(this.restService.post({
      url: '/deal/updateCalled',
      data
    }));
  }

  public async updateIsOpenOrderNotified(id: number, isOpenOrderNotified: boolean): Promise<any> {
    const data = {
      DealId: id,
      IsOpenOrderNotified: isOpenOrderNotified
    }
    return await this.handleResponse(this.restService.post({
      url: '/deal/updateIsOpenOrderNotified',
      data
    }));
  }

  public async updateIsDeliveryEmailSent(id: number, IsDeliveryEmailSent: boolean): Promise<any> {
    const data = {
      DealId: id,
      IsDeliveryEmailSent: IsDeliveryEmailSent
    }
    return await this.handleResponse(this.restService.post({
      url: '/deal/updateIsDeliveryEmailSent',
      data
    }));
  }

  /**
   * Changes is increase recently contacted flag 
   * @returns {Observable<any>} observable
   * @memberof DealService
   */

  public updateIsIncreasedRecentlyContactedRotting(data: any): Observable<any> {
    const newData = {
      DealId: data.dealId,
      IsIncreasedRecentlyContactedRotting: data.isIncreasedRecentlyContactedRotting
    };

    return from(this.handleResponse(this.restService.post({
      url: '/test/deal/updateIsIncreasedRecentlyContactedRotting',
      data: newData
    })));
  }

  /**
   * Changes is for clothier flag 
   * @returns {Observable<any>} observable
   * @memberof DealService
   */

  public updateIsForClothier(data: any): Observable<any> {
    return from(this.handleResponse(this.restService.post({
      url: '/test/deal/updateIsForClothier',
      data: { DealId: data.dealId, IsForClothier: data.isForClothier }
    })));
  }

  /**
   * Changes tracking link
   * @returns {Observable<any>} observable
   * @memberof DealService
   */

  public async updateTrackingLink(data: any): Promise<any> {
    return this.handleResponse(this.restService.post({
      url: '/deal/updateTrackingLink',
      data: { DealId: data.dealId, TrackingLink: data.trackingLink }
    }));
  }

  public async updateIsOrderShipped(id: number, isOrderShipped: boolean): Promise<any> {
    return await this.handleResponse(this.restService.post({
      url: '/deal/updateIsOrderShipped',
      data: { DealId: id, IsOrderShipped: isOrderShipped }
    }));
  }

  public async getExportListDealWithContactInfoAsCsv(
    start: number
    , limit: number
    , userIds: any
    , location: any
    , gender: any
    , stages: any
    , exportDealsType: ExportDealsType
    , fileName: string
    , companies?: any
    , positions?: any
    , isSameBuildingFilter?: boolean
    , isWithPhoneNumberOnlyFilter?: boolean
    , lastExportDealDateFrom?: any
    , lastExportDealDateTo?: any
    , isIncludeExportedLeads?: boolean
    , isIncludeUnexportedLeads?: boolean
  ): Promise<void> {

    let data = this.getListDealFilter(
      start
      , limit
      , null
      , userIds
      , location
      , gender
      , stages
      , companies
      , positions
      , isSameBuildingFilter
      , isWithPhoneNumberOnlyFilter
      , (lastExportDealDateFrom) ? DateHelper.formatAsISODateString(lastExportDealDateFrom) : lastExportDealDateFrom
      , (lastExportDealDateTo) ? DateHelper.formatAsISODateString(lastExportDealDateTo) : lastExportDealDateTo
      , isIncludeExportedLeads
      , isIncludeUnexportedLeads
    );
    data["exportDealsType"] = exportDealsType;
    const url = '/test/deals/export-list-with-contact-info-as-csv';
    const file = await this.handleResponse(this.restService.post({ url, responseType: 'blob', data }));
    saveAs(file, fileName + ".csv");
  }

  public async updatePreferToContactByEmail(id: number, preferToContactByEmail: boolean): Promise<any> {
    const data = {
      DealId: id,
      PreferToContactByEmail: preferToContactByEmail
    }
    return await this.handleResponse(this.restService.post({
      url: '/deal/updatePreferToContactByEmail',
      data
    }));
  }

  public async increaseRottingTime(id: number): Promise<any> {
    return await this.handleResponse(this.restService.post({
      url: '/test/deal/increaseRottingTime',
      data: { DealId: id }
    }));
  }

  public async increaseReselectRottingTimeCounter(id: number): Promise<any> {
    return await this.handleResponse(this.restService.post({
      url: '/test/deal/increaseReselectRottingTimeCounter',
      data: { DealId: id }
    }));
  }

  public async getStagesDealsSummaryByOwnerId(ownerId: number): Promise<any> {
    return await this.handleResponse(this.restService.get({
      url: `/test/deal/stages-deals-summary?ownerId=${ownerId}`
    }));
  }

  private createFilteredDealResult(response: any) {
    var fdm = new FilteredDealsModel();
    fdm.additional_data = response.additional_data;
    fdm.data = response.data.map(x => Mapper.transformFilteredDealToClient(x));
    return fdm;
  }

  private createFilteredContactResult(response: any) {
    const fdm = new FilteredByContactModel();
    fdm.additional_data = response.additional_data;
    fdm.data = response.data.map(x => Mapper.transformDealWithContactToClient(x));
    return fdm;
  }
}
