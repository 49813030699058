import { Injectable } from "@angular/core";
import { TwilioEffectsService } from "./twilio-effects.service";
import { Message } from "../../models/chat-models/Message";
import { Observable } from "rxjs";
import { Chat } from "../../models/chat-models/Chat";

@Injectable()
export class TwilioFacadeService {
    constructor(
        private twilioEffectsService: TwilioEffectsService,
    ) {
    }

    public setLoader(loader: boolean): void {
        this.twilioEffectsService.setLoader(loader);
    }

    public async sendWhatsappMessage(dealId: number, toPhone: string, message: string, file): Promise<any> {
        return await this.twilioEffectsService.sendWhatsappMessage(dealId, toPhone, message, file);
    }

    public async sendSmsMessage(dealId: number, toPhone: string, message: string, file): Promise<any> {
        return await this.twilioEffectsService.sendSmsMessage(dealId, toPhone, message, file);
    }

    public getLoaderObservable(): Observable<boolean> {
        return this.twilioEffectsService.getLoaderObservable();
    }

    public async loadMessages(chatId: string): Promise<Message[]> {
        return await this.twilioEffectsService.loadMessages(chatId);
    }

    public getMessagesObservable(): Observable<Message[]> {
        return this.twilioEffectsService.getMessagesObservable();
    }

    public getNewMessageObservable(): Observable<Message> {
        return this.twilioEffectsService.getNewMessageObservable();
    }

    public setNewMessage(message: Message): void {
        this.twilioEffectsService.setNewMessage(message);
    }

    public updateMessageStatus(message: Message): void {
        this.twilioEffectsService.updateMessageStatus(message);
    }

    public getActiveChatObservable(): Observable<Chat> {
        return this.twilioEffectsService.getActiveChatObservable();
    }

    public async setActiveChat(chat: Chat): Promise<void> {
        return await this.twilioEffectsService.setActiveChat(chat);
    }

    public async loadChatList(filter): Promise<Chat[]> {
        return await this.twilioEffectsService.loadChatList(filter);
    }

    public getChatListObservable(): Observable<Chat[]> {
        return this.twilioEffectsService.getChatListObservable();
    }

    public setChatList(chat: Chat[]): void {
        this.twilioEffectsService.setChatList(chat);
    }
}