import { ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, EventEmitter, Input, Output, ViewChild } from "@angular/core";
import { TwilioFacadeService } from "src/app/core/services/twilio/twilio-facade.service";
import { takeUntil } from "rxjs";
import { BaseComponent } from "src/app/core/base.component";
import { Chat } from "src/app/core/models/chat-models/Chat";
import { UserContextService } from "src/app/core/services/user-context.service";
import { User } from "src/app/core/models/UserModel";
import { RolesEnum } from "src/app/core/enums/RolesEnum";
import { UploadImageService } from "src/app/core/services/upload-image.service";
import { DomSanitizer } from "@angular/platform-browser";
import { AlertTypes } from "src/app/core/enums/AlertTypes";
import { AlertService } from "src/app/core/services/alert.service";
import { FileUtil } from "src/app/core/utils/file.util";

@Component({
  selector: 'pd-chat-footer',
  templateUrl: './chat-footer.component.html',
  styleUrls: ['./chat-footer.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})

export class ChatFooterComponent extends BaseComponent {
  @ViewChild('fileInput') fileInput: ElementRef;
  @ViewChild("targetTextarea") targetTextarea: ElementRef;

  @Input() public isGlobalChat: boolean;
  @Input() public isCandidateConversation: boolean;

  @Output() public onGrowCheck = new EventEmitter<any>();

  public newMessageText = '';
  public selectedFile: File;
  public pervUrl: any;
  public fileAccept = UploadImageService.allowedImageTypes.join(',');
  public isMessageSending: boolean = false;
  public isSpinner: boolean = false;
  public activeChat: Chat;

  public FileUtil = FileUtil;

  private currentUser: User;

  constructor(
    private ref: ChangeDetectorRef,
    private sanitizer: DomSanitizer,
    private userContextService: UserContextService,
    private alertService: AlertService,
    private twilioFacadeService: TwilioFacadeService,
  ) {
    super();

    twilioFacadeService.getActiveChatObservable().pipe(takeUntil(this.destroy$)).subscribe(chat => {
      this.activeChat = chat;
      this.newMessageText = '';
      this.resetFileInput();
      this.ref.markForCheck();
    });

    this.twilioFacadeService.getLoaderObservable().pipe(takeUntil(this.destroy$)).subscribe(loader => {
      this.isSpinner = loader;
      this.ref.markForCheck();
    });

    this.currentUser = this.userContextService.user.value;
  }

  ngAfterViewInit() {
    this.autoGrow();
  }

  public openInput() {
    this.resetFileInput();
    this.fileInput.nativeElement.click();
  }

  public changeListener(files: FileList) {
    if (files && files.length > 0) {
      var splitArray = files[0].name.split('.');
      var fileType = splitArray[splitArray.length - 1];
      if (!(fileType && (fileType.toLowerCase() === "png"
        || fileType.toLowerCase() === "jpg"
        || fileType.toLowerCase() === "jpeg"
        || fileType.toLowerCase() === "gif"
        || fileType.toLowerCase() === "webp"
      ))) {
        this.alertService.showAlert(AlertTypes.Error, 'Wrong file format. Please upload image file.', 'Noted');
        return;
      }
      this.selectedFile = files[0];
      this.pervUrl = this.sanitizer.bypassSecurityTrustUrl(URL.createObjectURL(this.selectedFile));
    }
  }

  public async sendMessage(): Promise<void> {
    if (this.newMessageText.trim() === '' && !this.selectedFile) {
      return;
    }

    const chat = this.activeChat;
    if (!chat) {
      return;
    }

    this.isMessageSending = true;
    this.twilioFacadeService.setLoader(true);

    if (chat.isWhatsApp) {
      const response = await this.twilioFacadeService.sendWhatsappMessage(chat.dealId, chat.chatPhone, this.newMessageText, this.selectedFile);
      if (!response.isSuccessful) {
        this.alertService.showAlert(AlertTypes.Error, response.errorMessage, 'Noted');
      }

      this.resetTextarea();
      this.resetFileInput();
    }
    else {
      const response = await this.twilioFacadeService.sendSmsMessage(chat.dealId, chat.chatPhone, this.newMessageText, this.selectedFile);
      if (!response.isSuccessful) {
        this.alertService.showAlert(AlertTypes.Error, response.errorMessage, 'Noted');
      }

      this.resetTextarea();
      this.resetFileInput();
    }

    this.twilioFacadeService.setLoader(false);
    this.isMessageSending = false;
    this.ref.detectChanges();
  }

  public removeAttach() {
    this.resetFileInput();
  }

  public get isAllowSendMessage() {
    return !this.isGlobalChat && !this.isCandidateConversation && this.currentUser
      && (this.currentUser.role_id == RolesEnum.MasterLead
        || this.currentUser.role_id == RolesEnum.EA
        || this.currentUser.role_id == RolesEnum.EaCaller
        || (this.currentUser.role_id === RolesEnum.Admin && this.currentUser.isWhatsappChat));
  }

  public get isDisabledSendMessage() {
    return this.isSpinner || this.isMessageSending || !this.activeChat?.dealId || this.activeChat?.isDeleted;
  }

  public autoGrow() {
    if (!this.targetTextarea) {
      return;
    }
    let textarea = this.targetTextarea.nativeElement;
    if (!this.newMessageText) {
      textarea.style.height = 70 + "px";
    }
    else {
      textarea.style.height = textarea.scrollHeight + "px";
    }
    this.ref.detectChanges();
    this.onGrowCheck.emit();
  }

  public get getPlaceholder() {
    if (!this.activeChat || this.isSpinner)
      return "";

    return this.activeChat && this.activeChat.isDeleted ? 'You cannot send the message to the removed phone number. To be able to send message here, you need to re-add the phone number to the “Deal” tab.'
      : 'Type your message here';
  }

  private resetTextarea() {
    this.newMessageText = '';
    this.autoGrow()
  }

  private resetFileInput() {
    this.selectedFile = null;
    this.pervUrl = null;
    if (this.fileInput) {
      this.fileInput.nativeElement.value = "";
    }
  }
}