<div class="row wrapper">
  <div class="col-3 title">
    <span style="color: red; font-size: 20px">*</span> Confirm the password
  </div>
  <div class="col-9 work-field">
    <div class="md-form" (document:focusout)="changeEdit()" (document:focusin)="changeEdit()">
      <input [(ngModel)]="confirmPassword" mdbActive type="password" required
        pattern="^(?=.*[a-z])(?=.*[A-Z])(?=\d*)(?=.*[0-9]).{7,}$" #inputConfirmPassword="ngModel"
        autocomplete="new-password">
      @if (inputConfirmPassword.invalid && inputConfirmPassword.touched) {
      <span style="color: red">The password must
        contain at least one digit and one uppercase character and have a length of at least 7 characters</span>
      }
    </div>
  </div>
</div>