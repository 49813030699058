<div class="wrapper" style="height: 100%; width: 100%;">
  <div>
    @if (isEdit) {
    <div>
      <div class="edit-in-progress">
        @for (emailRow of emails; track trackBy($index, emailRow)) {
        <div style="display: flex; margin-bottom: 15px;">
          <div>
            <div class="email-field">
              <pd-field-emails-input #inputFields (emailChange)="onEmailChange(emailRow, $event)"
                [email]="emailRow.value">
              </pd-field-emails-input>
            </div>
          </div>
          <div>
            <div class="label-field">
              <div class="select">
                <mat-form-field class="selector">
                  <mat-select [(value)]="emailRow.label">
                    @for (item of emailLabels; track item) {
                    <mat-option [value]="item">{{item}}</mat-option>
                    }
                  </mat-select>
                </mat-form-field>
              </div>
            </div>
          </div>
          <div>
            <div class="delete" (click)="deleteEmail($index)">
              <i style="margin-left: 12px;" class="material-icons">delete</i>
            </div>
          </div>
        </div>
        }
        <div class="tool-panel-wrapper">
          @if (emails.length == 0) {
          <div class="add-one-more-wraper">
            <div class="add-one-more add-value" (click)="addOneMore()">
              <div>+Add value</div>
            </div>
          </div>
          }
          @if (emails.length > 0) {
          <div class="add-one-more" (click)="addOneMore()">
            <div>+Add one more</div>
          </div>
          }
          @if (isEdit && isNeedSaveCancelBtn) {
          <div class="cancel-save-buttons">
            <button class="cancel-button" (click)="cancel()">Cancel</button>
            <button class="save-button" (click)="save()">Save</button>
          </div>
          }
        </div>
      </div>

      @if (duplicateError) {
      <ng-container>
        <div class="row alert-row error-message-wrapper">
          <div class="alert alert-danger error-message">Duplicate emails are not allowed.</div>
        </div>
      </ng-container>
      }
    </div>
    }
  </div>
</div>