<div class="row wrapper">
  <div class="col-3 title">
    <span style="color: red; font-size: 20px">*</span> Password
  </div>
  <div class="col-9 work-field">
    <div class="md-form" (document:focusout)="changeEdit()" (document:focusin)="changeEdit()">
      <input [(ngModel)]="password" mdbActive type="password" required autocomplete="new-password"
        pattern="^(?=.*[a-z])(?=.*[A-Z])(?=\d*)(?=.*[0-9]).{7,}$" #inputPassword="ngModel">
      @if (inputPassword.invalid && inputPassword.touched) {
      <span style="color: red">The password must contain at least
        one digit and one uppercase character and have a length of at least 7 characters</span>
      }
    </div>
  </div>
</div>