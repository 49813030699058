<div #targetMessagesInput class="message-input">
    @if (isAllowSendMessage) {

    @if (activeChat && activeChat.isWhatsApp) {
    <button (click)="openInput()" [disabled]="isDisabledSendMessage" class="attach-button">
        <mat-icon svgIcon="fa-paperclip" class="icon fa-paperclip"></mat-icon>Attach</button>
    }
    @if (pervUrl) {
    <div class="attached-file-info">
        <div class="file-preview">
            <img [src]="pervUrl" class="preview-thumbnail" />
            <button type="button" mdbBtn (click)="removeAttach()" class="close-btn waves-light btn-danger btn"
                style="float: right;" aria-label="Close" mdbWavesEffect>
                <i class="material-icons">close</i>
            </button>
        </div>
        <div class="file-details">
            <div>{{selectedFile.name}}</div>
            <div>{{FileUtil.fileSizeToView(selectedFile.size)}}</div>
        </div>
    </div>
    }
    <ng-container>
        <textarea #targetTextarea [placeholder]="getPlaceholder" maxlength="1600" [disabled]="isDisabledSendMessage"
            class="message-textarea" (paste)="autoGrow()" (keyup)="autoGrow()" [(ngModel)]="newMessageText"></textarea>
        <button (click)="sendMessage()" [disabled]="isDisabledSendMessage" class="send-button"
            [ngStyle]="{ 'justify-content': 'center', 'display': 'flex', 'align-items': 'center'}">
            @if (isMessageSending) {
            <div class="spinner" style="margin-right: 10px;">
                <mat-spinner [diameter]="20"></mat-spinner>
            </div>
            } Send
        </button>
        <input #fileInput type="file" class="upload" (change)="changeListener($event.target.files)"
            [disabled]="isDisabledSendMessage" [accept]="fileAccept" style="display: none">
    </ng-container>
    }
</div>