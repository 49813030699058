import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { CoreModule } from '../core/core.module';
import { CommonModule } from '@angular/common';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { SharedModule } from '../shared/shared.module';
import { CallsListComponent } from './call-details/calls-list.component';
import { CallDetailsComponent } from './calls-list/call-details.component';

@NgModule({
    imports: [
        FormsModule,
        CoreModule,
        CommonModule,
        MatProgressSpinnerModule,
        SharedModule
    ],
    declarations: [
        CallsListComponent,
        CallDetailsComponent,
    ],
    exports: [
        CallsListComponent,
        CallDetailsComponent,
    ]
})
export class CallModule { }
