import { Component, OnInit, Input, ViewChildren, QueryList, Output, EventEmitter } from '@angular/core';
import { FieldIntlTelInputComponent } from '../field-intl-tel-input/field-intl-tel-input.component';
import { ContactType } from 'src/app/core/enums/ContactType';

interface Phone {
  value: string;
  label: string;
  tempNewValue?: string;
  contactTypeId: ContactType;
}

@Component({
  selector: 'pd-field-intl-tel',
  templateUrl: './field-intl-tel.component.html',
  styleUrls: ['./field-intl-tel.component.scss']
})
export class FieldIntlTelComponent implements OnInit {
  @ViewChildren('inputFields') inputFields: QueryList<FieldIntlTelInputComponent>;

  @Input() phones: Phone[] = [];
  @Input() isSinglePhone: boolean = false;
  @Input() isNeedSaveCancelBtn = true;

  @Output() phonesSave = new EventEmitter<Phone[]>();
  @Output() phonesCancel = new EventEmitter<Phone[]>();

  public isEdit = false;
  public prevPhones: Phone[] = [];
  public readonly phoneLabels = ['Work', 'Home', 'Mobile', 'Other'];
  public duplicateError = false;

  ngOnInit() {
    this.prevPhones = this.deepClone(this.phones);
    this.isEdit = true;
  }

  public isAllValidCheck(): boolean {
    return this.areAllPhonesFieldsValid() && !this.hasDuplicatePhones();
  }

  private areAllPhonesFieldsValid(): boolean {
    return !(
      this.inputFields &&
      this.inputFields.some((input) => !input.checkIsValid())
    );
  }

  private hasDuplicatePhones(): boolean {
    const updatedPhones = this.getPhonesWithAppliedTempValues(this.phones);
    const phoneValues = updatedPhones.map((phone) => phone.value).filter((value) => !!value);
    this.duplicateError = phoneValues.some((phone, index) => phoneValues.indexOf(phone) !== index);
    return this.duplicateError;
  }

  public onPhoneChange(phone: Phone, updatedPhoneModel: { phone: string }): void {
    phone.tempNewValue = updatedPhoneModel.phone;
  }

  public addOneMore(): void {
    this.phones.push({ value: '', label: 'Work', contactTypeId: ContactType.Phone });
  }

  public deletePhone(index: number): void {
    this.phones.splice(index, 1);
  }

  public save(): void {
    if (!this.isAllValidCheck()) {
      return;
    }

    this.duplicateError = false;
    this.isEdit = false;

    const updatedPhones = this.getPhones().filter(phone => !!phone.value);
    this.phones = updatedPhones;
    this.prevPhones = this.deepClone(updatedPhones);
    this.phonesSave.emit(updatedPhones);
  }

  public getPhones(): Phone[] {
    this.phones = this.getPhonesWithAppliedTempValues(this.phones);
    this.prevPhones = this.deepClone(this.phones);
    return this.phones;
  }

  public cancel(): void {
    this.isEdit = false;
    this.phones = this.deepClone(this.prevPhones);
    this.phonesCancel.emit(this.phones);
  }

  public trackBy(index: number): number {
    return index;
  }

  private deepClone(array: Phone[]): Phone[] {
    return array.map(item => ({ ...item }));
  }

  private getPhonesWithAppliedTempValues(phones: Phone[]): Phone[] {
    return phones.map((phone) => {
      if (phone.tempNewValue !== undefined) {
        return { ...phone, value: phone.tempNewValue, tempNewValue: undefined };
      }
      return { ...phone };
    });
  }
}
