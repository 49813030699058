import { GlobalConstants } from 'src/app/core/global-constants';
import { RoleService } from 'src/app/core/services/role.service';
import { UserContextService } from 'src/app/core/services/user-context.service';
import { Component, OnInit, Input, ViewChild, OnChanges, SimpleChanges, EventEmitter, Output } from '@angular/core';
import { RolesEnum } from 'src/app/core/enums/RolesEnum';
import { Role } from 'src/app/core/models/Role';
import { User } from 'src/app/core/models/UserModel';
import { StageService } from 'src/app/core/services/stage.service';
import { UsersService } from 'src/app/core/services/users.service';
import { STAGES_BY_ROLES_VISIBILITY, STAGES_FOR_ALL_PIPELINES } from 'src/assets/stages_new_prospespects';
import { RolePropertyModel, AiAgentConfigModel } from 'src/app/core/models/RolePropertyModel';
import { CustomSelectFilterWithoutLazyComponent } from 'src/app/shared/custom/custom-select-without-lazy/custom-select-without-lazy';
import { ReplaySubject } from 'rxjs';
import { ObjectUtil } from 'src/app/core/utils/object.util';
import { CallsService, ElevenLabsAgent } from 'src/app/core/services/calls.service';

@Component({
  selector: 'pd-field-role',
  templateUrl: './field-role.component.html',
  styleUrls: ['./field-role.component.scss']
})
export class FieldRoleComponent implements OnInit, OnChanges {
  public isEdit = true;

  @Input() user: User;
  @Input() roles: Role[];
  @Input() disabled = false;

  @Output() roleChange = new EventEmitter<Object>();

  public customSelectUser: CustomSelectFilterWithoutLazyComponent;
  @ViewChild('userFilter') set userFilterRef(content: CustomSelectFilterWithoutLazyComponent) {
    if (content) {
      this.customSelectUser = content;
      this.getUsersFilterData();
    }
  }

  public roleList: Role[] = [];
  public isEaHasConnections = false;

  public roleProperty: RolePropertyModel = new RolePropertyModel();

  private allFilteredUsers = [];
  private filteredUsers = [];
  private filteredUserMultiSelect: ReplaySubject<User[]> = new ReplaySubject<User[]>(1);

  public availableAgents: ElevenLabsAgent[] = [];
  public voiceNumbers: string[] = [];

  public stageList = [
    { id: GlobalConstants.ALL_STAGES.Cold, name: "Cold" },
    { id: GlobalConstants.ALL_STAGES.Linkedin, name: "Linkedin" },
    { id: GlobalConstants.ALL_STAGES.SameBuilding, name: "Same Building" },
  ];

  constructor(
    public usersService: UsersService,
    private userContextService: UserContextService,
    private stageService: StageService,
    private roleService: RoleService,
    private callsService: CallsService
  ) { }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.user && changes.user.currentValue) {
      ObjectUtil.safeUpdate(this.roleProperty, this.user);
      console.log(this.roleProperty);
      this.roleProperty.role = this.roles.find(x => x.id === this.user.role_id);
      this.roleChange.emit(this.roleProperty.role);
    }
  }

  async ngOnInit(): Promise<void> {
    if (this.user && (this.user.role_id === RolesEnum.Admin || this.user.role_id === RolesEnum.EA || this.user.role_id === RolesEnum.EaCaller)) {
      const isEaHasConnections = await this.usersService.checkIsEaHasConnections(this.user.id);
      this.isEaHasConnections = isEaHasConnections;
    }

    if (!this.roles) {
      this.roles = await this.roleService.getAll();
    }
    this.availableAgents = await this.callsService.getAgents();
    this.voiceNumbers = await this.callsService.getVoiceNumbers();
    await this.initialiseStoreData();
    this.getUsersFilterData();
  }

  private async initialiseStoreData(): Promise<void> {
    const roles = [RolesEnum.Clothier];
    const result = await this.usersService.getUsers();

    this.allFilteredUsers = result.filter(x => roles.includes(x.role_id));

    this.roleProperty.role =
      this.user && this.roles && this.user.role_id
        ? this.roles.find(x => x.id == this.user.role_id)
        : null;

    if (this.user?.role_id) {
      const allStatusesWithDeals = await this.stageService.getStagesWithExistingDealByUserIdObservable(this.user.id);
      this.roleList = this.getAllowedRolesForUpdateUser(this.roles, allStatusesWithDeals, this.userContextService.user.value);
    } else {
      this.roleList = this.roles.filter(x => x.id != RolesEnum.MasterLead);
    }
    this.roleList = this.roleList.sort((a, b) => a.name.localeCompare(b.name));
  }

  private getAllowedRolesForUpdateUser(allRoles: Role[], allStatusesWithDeals: any[], currentUser: User): Role[] {
    if (!allRoles) return [];

    const filteredStages = STAGES_FOR_ALL_PIPELINES.filter(s =>
      allStatusesWithDeals.some(ds => ds.stage_id === s.id) &&
      ![GlobalConstants.ALL_STAGES.Kill, GlobalConstants.ALL_STAGES.KilledRecord].includes(s.id)
    );

    const allowedRoleIds = Object.entries(STAGES_BY_ROLES_VISIBILITY).reduce((allowedRoles, [roleName, stages]) => {
      const roleId = RolesEnum[roleName];
      if (roleId !== RolesEnum.MasterLead && stages?.length > 0 &&
        (currentUser.role_id === roleId || filteredStages.every(stage => stages.includes(stage.name)))) {
        allowedRoles.push(roleId);
      }
      return allowedRoles;
    }, []);

    return allRoles.filter(role => allowedRoleIds.includes(role.id));
  }

  public addValue() {
    this.isEdit = true;
  }

  public cancelEdit() {
    this.isEdit = false;
  }

  public goToEdit() {
    this.isEdit = true;
  }

  public changeValue() {
    this.roleChange.emit(this.roleProperty);
  }

  public isRoleDisabled(role: Role): boolean {
    return this.isEaHasConnections &&
      (role.id !== RolesEnum.Admin && role.id !== RolesEnum.EA && role.id !== RolesEnum.EaCaller);
  }

  public get isAllowedFlags(): boolean {
    return this.isAdmin || this.isClothier || this.isBB;
  }

  public get isAdmin(): boolean {
    return this.roleProperty.role && this.roleProperty.role.id === RolesEnum.Admin;
  }

  public get isClothier(): boolean {
    return this.roleProperty.role && this.roleProperty.role.id === RolesEnum.Clothier;
  }

  public get isBB(): boolean {
    return this.roleProperty.role && this.roleProperty.role.id === RolesEnum.BB;
  }

  public getUsersFilterData() {
    this.filteredUsers = [];
    this.filteredUsers = this.filteredUsers.concat(this.allFilteredUsers);
    this.filteredUserMultiSelect.next(this.allFilteredUsers.slice());

    if (this.customSelectUser) {
      this.customSelectUser.setData(this.filteredUsers);
      this.roleProperty.leaderOf.forEach(id => {
        const user = this.allFilteredUsers.find(x => x.id == id);
        if (user) {
          this.customSelectUser.selectItem(user, { checked: true });
        }
      });
      this.customSelectUser.closeCustomSelect();
    }
  }

  public getUsersAutocomplete(event: any) {
    let search = event.searchString;
    if (!search) {
      this.filteredUserMultiSelect.next(this.allFilteredUsers.slice());
      return;
    } else {
      search = search.toLowerCase();
    }
    this.filteredUserMultiSelect.next(
      this.allFilteredUsers.filter(loc => loc.name.toLowerCase().indexOf(search) > -1)
    );

    this.filteredUsers = [];
    this.filteredUserMultiSelect.subscribe(x => {
      for (let i = 0; i < x.length; i++) {
        if (this.filteredUsers.length === 0) {
          this.filteredUsers.push(x[0]);
        } else {
          if (!this.filteredUsers.includes(x[i], 0)) {
            this.filteredUsers.push(x[i]);
          }
        }
      }
    });
    this.customSelectUser.setData(this.filteredUsers);
  }

  public onSelectedItem() {
    this.roleProperty.leaderOf = this.customSelectUser.dataArray.map(u => u.id);
  }

  public onAgentChanged(config: AiAgentConfigModel) {
    const agent = this.availableAgents.find(a => a.agentId === config.agentId);
    config.agentName = agent ? agent.agentName : '';
  }

  public addAiAgentConfig() {
    const newConfig: AiAgentConfigModel = {
      stageId: null,
      agentId: '',
      agentName: '',
      phone: ''
    };
    this.roleProperty.aiAgentConfigs.push(newConfig);
  }

  public removeAiAgentConfig(index: number) {
    this.roleProperty.aiAgentConfigs.splice(index, 1);
  }

  public isStageDisabled(stageId: number, currentIndex: number): boolean {
    return this.roleProperty.aiAgentConfigs.some(
      (cfg, idx) => idx !== currentIndex && cfg.stageId === stageId
    );
  }
}
