<div class="row wrapper">
  <div class="col-3 title">
    @if (isShowRequired) {
    <span style="color: red; font-size: 20px">*</span>
    }Type of clothier:
  </div>
  <div class="col-9 checkbox-wrapper" (document:focusout)="changeEdit()" (document:focusin)="changeEdit()">
    <mat-checkbox class="checkbox" [(ngModel)]="isLocal">Local</mat-checkbox>
    <mat-checkbox class="checkbox" [(ngModel)]="isTravel">Travel</mat-checkbox>
    @if (!isTravelLocalIsValid) {
    <div style="position: absolute; color: red">Choose the type of clothier</div>
    }
  </div>
</div>