<mat-dialog-content>
  <div *ngFor="let phone of phones" style="margin-bottom: 8px;">
    <span>{{ getPhoneNumber(phone)}}</span>
    <button mat-raised-button color="primary" style="margin-left: 16px;" (click)="call(phone)"
      [disabled]="isCalling">
      Call
    </button>
  </div>
</mat-dialog-content>

<mat-dialog-actions>
  <button mat-button (click)="close()">Close</button>
</mat-dialog-actions>