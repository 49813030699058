<div class="call-details-container" *ngIf="call">
  <h3>Call details (ID: {{ call.id }})</h3>
  <hr />

  <div class="dialog-section">
    <h4>Conversation from ElevenLabs</h4>

    <div *ngIf="isLoadingConversation">Loading conversation...</div>
    <div *ngIf="conversationError" class="error">{{ conversationError }}</div>

    <div
      class="transcript-line"
      *ngFor="let t of conversationTranscript"
    >
      <b>{{ t.role }} ({{ t.time_in_call_secs }}s):</b> {{ t.message }}
    </div>
  </div>

  <hr />

  <div class="audio-section">
    <h4>Audio from ElevenLabs</h4>

    <div *ngIf="isLoadingAudio">Loading audio...</div>
    <div *ngIf="audioError" class="error">{{ audioError }}</div>

    <audio
      *ngIf="audioUrl && !audioError"
      [src]="audioUrl"
      controls
    ></audio>
  </div>
</div>
