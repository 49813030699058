<div class="snackbar-content">
  <div class="icon">
    <ng-container *ngIf="data.type === 'error'">
      <img src="/assets/error-icon.svg" alt="Error Icon" />
    </ng-container>

    <ng-container *ngIf="data.type === 'success'">
      <img src="/assets/success-icon.svg" alt="Success Icon" />
    </ng-container>
  </div>

  <span>{{ data.message }}</span>
</div>