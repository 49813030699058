<div class="container">
  <div class="table-column">
    <table>
      <thead>
        <tr>
          <th>ID</th>
          <th>CallSid</th>
          <th>ConversationId</th>
          <th>DealId</th>
          <th>Prospect Name</th>
          <th>User PD Name</th>
          <th>Ai Agent Name</th>
          <th>Location</th>
          <th>Stage</th>
          <th>Call Status</th>
          <th>From</th>
          <th>To</th>
          <th>Call Duration</th>
        </tr>
      </thead>
      <tbody>
        <tr
          *ngFor="let call of calls"
          (click)="onSelectCall(call)"
          [class.selected]="call.id === selectedCall?.id"
          style="cursor: pointer;"
        >
          <td>{{ call.id }}</td>
          <td>{{ call.callSid }}</td>
          <td>{{ call.conversationId }}</td>
          <td>{{ call.dealId }}</td>
          <td>{{ call.dealTitle }}</td>
          <td>{{ call.ownerName }}</td>
          <td>{{ call.agentName }}</td>
          <td>{{ call.location }}</td>
          <td>{{ getStageName(call.stageId) }}</td>
          <td>{{ call.callStatus }}</td>
          <td>{{ call.fromNumber }}</td>
          <td>{{ call.toNumber }}</td>
          <td>{{ formatDuration(call.callDuration) }}</td>
        </tr>
      </tbody>
    </table>
  </div>

  <div class="details-column">
    <pd-call-details
      [call]="selectedCall"
    ></pd-call-details>
  </div>
</div>
